import * as React from "react"
import {Layout} from "../components/Layout";
import * as styles from "../styles/pages/works.module.scss"
import {WorksPageModel} from "../store/models/works-page";
import OutlineButton from "../components/common/button/OutlineButton";
import SEO from "../components/SEO";
import StyledText, {TextStyles} from "../components/typography";
import StrapiClient, {StrapiResponse, StrapiSingleTypes} from "../strapi-client/strapi-client";
import {MenuModel} from "../store/models/menu";
import {GetServerDataProps, GetServerDataReturn} from "gatsby";
import {scrollToAnchor} from "../helpers/scroll-to-anchor";
import {WorksItem} from "../components/works/WorksItem";


type WorksPageProps = {
    serverData: {
        menu: StrapiResponse,
        page: StrapiResponse
    },
    location: any
}

const WorksPage = (props: WorksPageProps) => {
    const worksPageModel: WorksPageModel = WorksPageModel.fromJSON(props.serverData.page);
    const menuModel: MenuModel = MenuModel.fromJSON(props.serverData.menu);

    return (
        <Layout menu={menuModel} location={props?.location}>
            <SEO seo={worksPageModel.seo}></SEO>
            <div className={styles.header}>
                <div className={styles.titles}>
                    <StyledText className={styles.title} variant={TextStyles.h1} tag="h1">
                        {worksPageModel.title}
                    </StyledText>
                    <StyledText tag='p' className={styles.subtitle} variant={TextStyles.bodyChoose}>
                        {worksPageModel.description}
                    </StyledText>
                </div>

                <div className={styles.types}>
                    {worksPageModel.works && worksPageModel.works.map((workLink, index: number) => (
                        workLink.work ?
                            <div className={styles.type} key={index}>
                                <OutlineButton onClick={() => scrollToAnchor(`${workLink.work?.slug}`)}>
                                    <StyledText variant={TextStyles.bodyText}>
                                        {workLink.work.title}
                                    </StyledText>
                                </OutlineButton>
                            </div>
                            : <></>
                    ))}
                </div>
            </div>


            <div style={{display: 'flex', flexDirection: 'column'}}>
                {worksPageModel.works && worksPageModel.works.map((workLink, index: number) => (
                    workLink.work ?
                        <WorksItem work={workLink.work} worksPageModel={worksPageModel} index={index}/>
                        : <></>
                ))}
            </div>
        </Layout>
    )
}

export default WorksPage

export async function getServerData(context: GetServerDataProps): GetServerDataReturn {
    try {
        const locale: string = context.pageContext.locale as string;
        const strapi = new StrapiClient(locale);
        const page = await strapi.getSingleType(StrapiSingleTypes.worksPage);
        const menu = await strapi.getSingleType(StrapiSingleTypes.menu);

        return {
            props: {
                page,
                menu
            },
        }
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {}
        }
    }
}
