import * as styles from "../../styles/pages/works.module.scss";
import StyledText, {TextStyles} from "../typography";
import {InitiatorModel, WorksPageModel, WorksPageWork} from "../../store/models/works-page";
import LinkText from "../LinkText";
import * as React from "react";

type WorksItemProps = {
    work: WorksPageWork,
    index: number,
    worksPageModel: WorksPageModel
}

const getNumberWithZero: (number: number) => string = (number: number) => ('0' + (number)).slice(-2);

export const WorksItem = ({ work, worksPageModel, index, }: WorksItemProps) => {
    return (
        <section className={styles.workContainer} key={index} id={work.slug || ''}>
            <div className={styles.workImageBox}>
                <StyledText variant={TextStyles.h4} mr={5}>
                    {getNumberWithZero(index + 1)}/
                </StyledText>
                <img
                    src={work.logo?.fullUrl}
                    className={styles.workImage}
                    alt={work.logo?.alt}
                />
            </div>

            <div className={styles.workTitleBox}>
                <StyledText id={`work-${index}`} variant={TextStyles.h2Caps} tag='h2'
                            className={styles.workTitle}>
                    {work.title}
                </StyledText>

                <ul>
                    {work.list && work.list.map((li: any, index: number) => (
                        <StyledText variant={TextStyles.bodyText} className={styles.listItemTitle} tag="li">
                            {li.text}
                        </StyledText>
                    ))}
                </ul>

                <div>
                    {(work.initiators && work.initiators.length > 0) && (
                        <StyledText variant={TextStyles.h5} className={styles.residentsLabel}>
                            {worksPageModel.residentsLabel}
                        </StyledText>
                    )}
                    {work.initiators && work.initiators.map((item: InitiatorModel, index: number) => (
                        <div key={index}>
                            <div className={styles.memberLogo}>
                                <img
                                    src={item.image?.fullUrl}
                                    alt={item.image?.alt}
                                />
                            </div>

                            <StyledText variant={TextStyles.bodyText} className={styles.description}>
                                {item.description}
                            </StyledText>
                            {
                                item.link?.link && (
                                    <LinkText
                                        href={item.link.link}
                                        textVariant={TextStyles.bodyTextBold}
                                        className={styles.link}
                                    >
                                        {worksPageModel.readMoreLabel}
                                    </LinkText>
                                )
                            }
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

