import React, {ReactNode} from "react";
import * as styles from '../../../styles/components/outline-button.module.scss'

type Props = {
    onClick: () => void;
    children: ReactNode;
}

const OutlineButton = (props: Props) => {
    const {onClick, children} = props;
    return (
        <div onClick={onClick} className={styles.root}>
            {children}
        </div>
    )
}

export default OutlineButton
