// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var description = "works-module--description--5af2b";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var header = "works-module--header--13554";
export var link = "works-module--link--d3832";
export var listItemIcon = "works-module--listItemIcon--652c7";
export var listItemTitle = "works-module--listItemTitle--3dcb2";
export var member = "works-module--member--50430";
export var memberLogo = "works-module--memberLogo--047c9";
export var primaryColor = "#fff";
export var residentsLabel = "works-module--residentsLabel--2c974";
export var secondaryColor = "#000";
export var subtitle = "works-module--subtitle--d2cf2";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";
export var title = "works-module--title--3319e";
export var titles = "works-module--titles--bedda";
export var type = "works-module--type--5478a";
export var types = "works-module--types--5e18c";
export var workContainer = "works-module--workContainer--5cd9e";
export var workImage = "works-module--workImage--d1017";
export var workImageBox = "works-module--workImageBox--41b9c";
export var workTitle = "works-module--workTitle--d1084";
export var workTitleBox = "works-module--workTitleBox--5fabf";